<template>
  <v-container
    id="icons"
    fluid
  >
    <!--  -->
    <v-row
      align="center"
      justify="center"
      class="d-none d-sm-flex"
    >
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-eye"
          title="Total"
          :value="String(summary.total)"
          style="cursor: pointer;"
          @click.native="setStatus('')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="rgb(233, 30, 99)"
          icon="mdi-book-open-page-variant"
          title="Open"
          :value="String(summary.open)"
          style="cursor: pointer;"
          @click.native="setStatus('open')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-do-not-disturb"
          title="Close"
          :value="String(summary.close)"
          style="cursor: pointer;"
          @click.native="setStatus('close')"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-account-remove"
          title="Dihapus"
          :value="String(summary.deleted)"
          style="cursor: pointer;"
          @click.native="setStatus('deleted')"
        />
      </v-col>
    </v-row>
    <!--  -->
    <!-- Summary mobile -->
    <v-row
      align="center"
      justify="center"
      class="d-flex d-sm-none"
    >
      <!--  -->
      <v-col
        cols="12"
        style="padding: 0"
      >
        <!--  -->
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            <v-badge
              color="green"
              :content="item.summary"
            >
              {{ item.title }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <v-card flat>
              <v-card-text>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="info"
                    :icon="item.icon"
                    :title="item.title"
                    :value="String(item.summary)"
                    style="cursor: pointer;"
                    @click.native="setStatus(item.status)"
                  />
                </v-col>
                {{ item.content }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!-- ENd sumary mobile -->
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="q"
          placeholder="Cari Judul Thread"
          append-icon="mdi-magnify"
          :label="$t('search')"
          color="secondary"
          hide-details
          @keyup="search"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="2"
        lg="2"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          to="/forum/create"
          left
          width="100%"
          color="primary"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Buat Baru
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="12"
      >
        <base-material-card
          icon="mdi-forum"
          title="Forum"
          color="primary"
          class="px-6 py-3"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left primary--text">
                        #
                      </th>
                      <th class="text-left primary--text">
                        Judul Thread
                      </th>
                      <th class="text-left primary--text">
                        Komentar
                      </th>
                      <th class="text-left primary--text">
                        Dibuat pada
                      </th>
                      <th class="text-left primary--text">
                        Status
                      </th>
                      <th class="text-center primary--text">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <tr
                      v-for="(data, index) in Forum.data"
                      :key="data.id"
                    >
                      <td>{{ index + Forum.meta.from }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.count_comment }} Komentar</td>
                      <td>{{ data.created_at | moment('D MMM YYYY') }}</td>
                      <td>{{ data.status }}</td>
                      <td class="text-center">
                        <v-tooltip
                          bottom
                          color="blue"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              icon
                              color="blue"
                              v-bind="attrs"
                              :to="'/forum/' + data.id + '/show'"
                              v-on="on"
                            >
                              <v-icon small>
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Views</span>
                        </v-tooltip>

                        <v-tooltip
                          bottom
                          color="blue"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              icon
                              color="blue"
                              v-bind="attrs"
                              :to="'/forum/' + data.id + '/edit'"
                              v-on="on"
                            >
                              <v-icon small>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>

                        <v-tooltip
                          bottom
                          color="red"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              color="red lighten-2"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialog(data.id, data.title)"
                            >
                              <v-icon small>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Forum.meta.last_page"
                  total-visible="5"
                  @input="getResults"
                />
              </div>
            </div>
          </template>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="dialog.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text>
                Anda yakin akan menghapus data Forum dengan judul "{{
                  dialog.title
                }}"?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="dialog.open = false"
                >
                  BATAL
                </v-btn>
                <v-spacer />
                <v-btn
                  color="error"
                  @click="deleteItem(dialog.id)"
                >
                  HAPUS
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Forum',

    data: function () {
      return {
        Forum: {
          meta: {},
          data: {},
          category: {},
        },
        summary: {
          total: 0,
          open: 0,
          close: 0,
          deleted: 0,
        },
        items: [
          {
            tab: 'event',
            title: 'Total',
            icon: 'mdi-send',
            summary: '0',
            status: '',
          },
          {
            tab: 'open',
            title: 'Open',
            icon: 'mdi-send-check',
            summary: '0',
            status: 'open',
          },
          {
            tab: 'close',
            title: 'Close',
            icon: 'mdi-send-lock',
            summary: '0',
            status: 'close',
          },
          {
            tab: 'deleted',
            title: 'Dihapus',
            icon: 'mdi-trash-can',
            summary: '0',
            status: 'deleted',
          },
        ],
        tab: null,
        isLoading: false,
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
        offset: this.$route.query.offset || 10,
        status: this.$route.query.status || '',
      }
    },
    watch: {
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
        console.log('masuk')
      },
      '$route.query.status': function (val) {
        this.status = val
      },
    },
    mounted () {
      this.getSummary()
      this.getResults()
      this.scroll()
    },
    methods: {
      scroll () {
        window.onscroll = () => {
          console.log(window.innerHeight)
          const bottomOfWindow =
            document.documentElement.scrollTop + window.innerHeight ===
            document.documentElement.offsetHeight

          if (bottomOfWindow) {
          //
          }
        }
      },
      setStatus (val) {
        this.status = val
        this.getResults()
      },
      openDialog (id, title) {
        this.dialog.id = id
        this.dialog.title = title
        this.dialog.open = true
      },
      deleteItem (id) {
        this.dialog.open = false
        axios.delete('/v1/forum/thread/' + id).then(response => {
          if (response.data.meta.status) {
            this.$toast.error('Berhasil menghapus data', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            console.log(response.data)
          }

          // Update
          this.getResult()
        })
      },
      Remove (id) {
        axios.delete('/v1/forum/thread/' + id).then(response => {
          this.$toast.error('sukses Hapus Keranjang', {
            type: 'error',
            position: 'top-right',
            duration: 3000,
            dismissible: true,
          })
          // Update
          this.getResults()
        })
      },
      setSummary (data) {
        this.summary = data
        this.items.forEach(item => {
          if (item.status === '') {
            item.summary = this.summary.total
          } else if (item.status === 'open') {
            item.summary = this.summary.open
          } else if (item.status === 'close') {
            item.summary = this.summary.close
          } else if (item.status === 'deleted') {
            item.summary = this.summary.deleted
          } else {
            item.summary = 0
          }
        })
      },
      getSummary () {
        axios.get('/v1/forum/thread/summary').then(response => {
          this.setSummary(response.data.data)
        })
      },
      search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      setForum (data) {
        this.Forum = data
      },
      // Our method to GET results from a API endpoint
      getResults (page = this.page) {
        this.isLoading = false
        let search = ''
        if (this.q !== '') {
          search = '?q=' + this.q
        }
        const params = {
          entities: 'category,cover',
          status: this.status,
          page: page,
          sort: '-id',
        }
        axios
          .get('/v1/forum/thread' + search, {
            params: params,
          })
          .then(response => {
            this.setForum(response.data)
            this.isLoading = true
          })
      },
    },
  }
</script>
